.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    text-align: center;
    color: white;
    padding: 20px;
    max-width: 90%;
    box-sizing: border-box;
}

.overlay-message {
    margin-top: 10px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 18px;
    line-height: 1.4;
    max-width: 100%;
}

.poop-spin {
    animation: spin 2s linear infinite;
    width: 100px;
    height: 100px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Mobile-specific adjustments */
@media (max-width: 480px) {
    .overlay-content {
        padding: 15px;
        max-width: 95%;
    }
    
    .overlay-message {
        font-size: 14px;
    }
    
    .poop-spin {
        width: 80px;
        height: 80px;
    }
}