/* Add these styles for the modal footer buttons on mobile */

@media (max-width: 767.98px) {
  /* Make modal footer display buttons in a wrapped grid */
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }
  
  /* Make buttons fit content rather than taking up equal space */
  .modal-footer .btn {
    flex: 0 0 auto;
    width: auto;
    min-width: auto;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 0.875rem;
  }
  
  /* Special case for short link button which can be wider */
  .modal-footer .btn-success {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  /* Stack buttons on extra small screens */
  @media (max-width: 375px) {
    .modal-footer {
      flex-direction: column;
    }
    
    .modal-footer .btn {
      width: 100%;
    }
  }
}

/* Ensure modal body content is properly spaced */
.modal-body .row {
  margin-bottom: 1rem;
}

/* Make sure images and videos are contained */
.post-img-modal, .stash-modal-video {
  max-width: 100%;
  height: auto;
}