.meme-face-card {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    height: 100%;
}

.meme-face-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.meme-face-title {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.meme-face-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    overflow: hidden;
}

.meme-face-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.meme-face-owner {
    font-size: 0.75rem;
}