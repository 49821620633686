.image-overlay-box {
    transition: border-color 0.2s ease;
}

.image-overlay-box:hover {
    border-width: 3px;
}

.image-overlay-box img {
    pointer-events: none;
}

.react-draggable-dragging .image-overlay-box {
    opacity: 0.8;
}

.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
}

.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
}

.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
}

.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -5px;
    cursor: ew-resize;
}

.react-resizable-handle-w {
    left: 0;
}

.react-resizable-handle-e {
    right: 0;
}

.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -5px;
    cursor: ns-resize;
}

.react-resizable-handle-n {
    top: 0;
}

.react-resizable-handle-s {
    bottom: 0;
}

/* Hide resizing handles during screenshot capture */
.capturing-screenshot .react-resizable-handle {
    display: none !important;
}