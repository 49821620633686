.text-overlay-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    font-weight: bold;
    overflow: hidden;
    word-break: break-word;
}

.text-overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;  /* Allow clicking through to image */
}

.text-overlay-container > * {
    pointer-events: auto;  /* Re-enable pointer events for draggable elements */
}

.react-resizable {
    position: relative;
}

/* Base styles for all resize handles */
.react-resizable-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 50%;
    z-index: 999; /* Higher z-index than other elements */
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
    touch-action: none; /* Prevent default touch behavior */
    pointer-events: all; /* Ensure handles capture all pointer events */
}

/* Make sure handles are separated from the box edges a bit more */
.react-resizable-handle-se {
    bottom: -7px;
    right: -7px;
    cursor: se-resize !important;
}

.react-resizable-handle-sw {
    bottom: -7px;
    left: -7px;
    cursor: sw-resize !important;
}

.react-resizable-handle-nw {
    top: -7px;
    left: -7px;
    cursor: nw-resize !important;
}

.react-resizable-handle-ne {
    top: -7px;
    right: -7px;
    cursor: ne-resize !important;
}

.react-resizable-handle-n {
    top: -7px;
    left: 50%;
    margin-left: -6px;
    cursor: n-resize !important;
}

.react-resizable-handle-s {
    bottom: -7px;
    left: 50%;
    margin-left: -6px;
    cursor: s-resize !important;
}

.react-resizable-handle-e {
    right: -7px;
    top: 50%;
    margin-top: -6px;
    cursor: e-resize !important;
}

.react-resizable-handle-w {
    left: -7px;
    top: 50%;
    margin-top: -6px;
    cursor: w-resize !important;
}

/* Ensure resize handles are visible on hover */
.react-resizable:hover .react-resizable-handle {
    opacity: 1;
}

/* Improve handle visibility on hover */
.react-resizable-handle:hover {
    background-color: #007bff;
    border-color: #0056b3;
    transform: scale(1.2);
    transition: transform 0.1s ease;
}

/* Add these styles to your CSS file */
.capturing-screenshot .react-resizable-handle {
  display: none !important;
  opacity: 0 !important;
}

.capturing-screenshot .text-overlay-box {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}