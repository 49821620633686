.meme-template-page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.meme-template-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px !important;    
    width: 100%; /* Ensure the container takes full width */
    max-width: 1200px; /* Set a fixed maximum width */
    margin-left: auto; /* Center the container */
    margin-right: auto; /* Center the container */
    padding-top: 20px; /* Add padding to the top of the meme list to prevent content from hiding behind the sticky header */
}

.meme-template-list-img {
    width: 100%;    
    object-fit: cover; /* Ensure the image covers the area without distortion */
    flex-grow: 1;
    margin-bottom: 8px;
}

.meme-template-list-item {
    border: 1px solid #dee2e6; /* Light gray border similar to react-bootstrap */
    border-radius: 0.25rem; /* Rounded corners consistent with react-bootstrap */
    padding: 10px; /* Add some padding inside the container */
    cursor: pointer;
}

.meme-template-list-item-button {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Align to the bottom */
    width: 100%; /* Ensure the button takes full width */
    margin-top: 2px; /* Add some space between the image and the button */
    gap: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
    padding: 4px 8px; /* Slightly reduce the vertical padding */
}

.meme-template-list-item-button.mb-1 {
    margin-bottom: 0.25rem !important; /* Reduce from 0.25rem (bootstrap default for mb-1) to 0.15rem */
}

.meme-template-list-item-button svg {
    margin-bottom: 0;  /* Reset any default margins */
}

.meme-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.meme-name {
    text-align: center;
    font-weight: bold;
}

.pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 5px;
}

.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: none;
}

.non-interactable {
    pointer-events: none;
}

.modal-image {
    width: 100%;
    margin-bottom: 10px;
}

.modal-text-input {
    margin-bottom: 10px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 8px;
    width: 100%;
}

.d-flex {
    display: flex;
}

.me-2 {
    margin-right: 0.5rem;
}

.meme-filter-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.5rem;
}

.filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    min-width: 90px;
}

.filter-controls-container {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease;
    position: sticky;
    top: 56px; /* Match navbar height */
    z-index: 999;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-controls-container.is-sticky {
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
    .meme-filter-controls {
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
    }
}

@media (max-width: 576px) {
    .meme-template-list-item-button {
        font-size: 0.8rem;
        padding: 5px 6px;
    }
}