.modal-text-input.form-control {
    resize: vertical;
    min-height: 38px;
}

.input-group-with-icon {
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    align-items: start;
}

.input-group-with-icon .input-icon {
    position: absolute;
    left: 10px;
    top: 15px;
    color: #6c757d;
    z-index: 1; /* Lower z-index to prevent overlap with form controls */
}

.input-group-with-icon .flex-grow-1 {
    width: 100%;
    padding-left: 30px;
}

/* Fix for MemeFacePicker within MemeModal */
.input-group-with-icon .flex-grow-1 .form-label {
    display: none; /* Hide the label from MemeFacePicker since we're using a consistent layout */
}

.input-group-with-icon .form-control {
    padding-left: 35px; /* Make room for the icon */
}

/* Ensure input groups have proper spacing */
.modal-body .input-group {
    margin-bottom: 0.5rem;
    width: 100%;
}

/* Fix alignment for the ButtonGroup in MemeFacePicker */
.input-group-with-icon .btn-group {
    margin-left: -1px;
}

/* Make thumbnail display properly in button */
.input-group-with-icon .btn img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.modal-body .mb-3 {
    margin-bottom: 1rem !important;
}

/* Ensure the text area has a consistent style */
.modal-text-input {
    width: 100%;
    min-height: 60px;
}

/* Make sure thumbnails display properly */
.modal-body .btn img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

/* Remove any padding that could cause layout issues */
.modal-body .flex-grow-1 {
    padding: 0;
}