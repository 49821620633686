.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: none;
}

.non-interactable {
    pointer-events: none;
}

.modal-image {
    width: 100%;
    margin-bottom: 10px;
}

.modal-text-input {
    margin-bottom: 10px;
}

.input-group-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-icon {
  margin-right: 10px;
  width: 20px;
  color: #6c757d;
  text-align: center;
}

.modal-text-input {
  flex: 1;
}