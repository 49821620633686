.stash-search-items-per-page {
    width: 3em;
    margin-right: 0.25em;
}


.stash-pagination {    
    padding-left: 0.25em;
    padding-right: 0.25em;
    display: inline-block;    
    margin-right: 0.25em;    
}


@media screen and (max-width: 500px) {

    .stash-pagination {
        margin-top: 0.25em;
        float: right;        
        width: 100%;
    }
    .stash-pagination .stash-search-items-per-page {
        width: 45%;
        float: left;
    }    
    .stash-pagination .stash-pagination-buttons {
        width: 45%;
        float: right;
        margin-right: 0px;
    }

    
    .stash-search-buttons {
        width: 100%;
    }

}

/* Selection mode styles */
.stash-selection-controls {
    margin-left: 10px;
}

.post-selected {
    position: relative;
    outline: 3px solid #0d6efd;
    box-shadow: 0 0 10px rgba(13, 110, 253, 0.5);
}

.selection-indicator {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selection-icon {
    font-size: 18px;
    color: #6c757d;
}

.selection-icon.selected {
    color: #0d6efd;
}

.selection-status {
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
}

/* Add this to your Stash.css file */
.drop-area-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.drop-message {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.drop-message h3 {
    margin-top: 15px;
    color: #333;
}

.drop-message svg {
    color: #0d6efd;
}

/* Infinite scroll loading indicator */
.loading-more {
    text-align: center;
    padding: 20px 0;
    color: #6c757d;
}

.loading-more .spinner-border {
    width: 2rem;
    height: 2rem;
    margin-right: 10px;
}

.end-of-results {
    text-align: center;
    padding: 20px 0;
    color: #6c757d;
    font-style: italic;
}

/* Sticky filter controls */
.stash-filters-container {
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease;
    position: sticky;
    top: 56px; /* Match navbar height - adjust if needed */
    z-index: 1030;
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
    overflow: visible;
    border-radius: 6px;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
    overflow: visible !important;
}

.stash-filters-container.is-sticky {
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
    border-bottom: 1px solid #e9ecef;
}

/* Add padding to make space between the fixed header and content */
.stash {
    padding-top: 10px;
}

.stash-filters-row {
    flex-wrap: wrap;
    padding: 8px 0;
    align-items: flex-start;
    padding: 10px 0;
    overflow: visible !important;
    position: relative;
}

.stash-actions-row {
    padding: 10px 0;
    border-top: 1px solid #eee;
}

@media screen and (max-width: 768px) {
    .stash-filters-container {
        padding: 8px 0;
    }
    
    /* Make buttons stack more cleanly on mobile */
    .stash-filters-container .btn,
    .stash-filters-container .btn-group {
        margin-bottom: 5px;
    }
    
    /* Add more space for content under sticky header on mobile */
    .stash {
        padding-top: 15px;
    }
}

/* Add this to your Stash.css file */
@media (max-width: 767px) {
  .stash-filters-container {
    margin-bottom: 10px;
  }
  
  .stash-filters-row {
    flex-direction: column;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    border: 1px solid #dee2e6;
  }
  
  .stash-filters-row .btn,
  .stash-filters-row .btn-group {
    margin-top: 8px;
    width: 100%;
  }
  
  .stash-search-input {
    width: 100%;
    margin-bottom: 8px;
  }
  
  .stash-search-buttons {
    width: 100%;
    display: flex;
  }
  
  .stash-search-button {
    flex-grow: 1;
  }
}

/* Add these styles at the end of your file */
.filter-collapse-container {
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out, margin 0.2s ease-out;
    overflow: hidden;
    overflow: visible !important;
    position: relative;
}

.filter-collapse-container.collapsed {
    max-height: 0;
    opacity: 0;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden !important; /* Only hide when collapsed */
    z-index: auto;
}

.filter-collapse-container.expanded {
    max-height: 500px; /* Adjust based on your content */
    opacity: 1;
    margin-bottom: 15px;
    overflow: visible !important;
}

@media (min-width: 768px) {
    /* On desktop always show filters */
    .filter-collapse-container {
        max-height: none !important;
        opacity: 1 !important;
        overflow: visible;
    }
}

/* Mobile-specific improvements */
@media (max-width: 767.98px) {
    .stash-filters-row {
        margin: 0;
        padding: 15px;
        border-radius: 5px;
        background-color: #f8f9fa;
        border: 1px solid #dee2e6;
        box-shadow: 0 2px 5px rgba(0,0,0,0.05);
    }
    
    /* Add separation between buttons */
    .stash-filters-row .btn,
    .stash-filters-row .btn-group,
    .stash-filters-row .dropdown,
    .stash-filters-row input {
        margin-bottom: 8px;
    }
    
    /* Make all buttons full width on mobile for easier tapping */
    .stash-filters-row .btn,
    .stash-filters-row .btn-group,
    .stash-filters-row .dropdown,
    .stash-content-type-dropdown-button,
    .stash-tag-dropdown-button {
        display: block;
        width: 100%;
    }
    
    /* Add spacing under the container */
    .stash-filters-container {
        margin-bottom: 10px;
    }
}

/* Add or update these styles at the end of your file */

@media (max-width: 767.98px) {
    /* Reset any left margins on mobile that might be causing misalignment */
    .stash-filters-row .btn,
    .stash-filters-row .btn-group {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    /* Make buttons appear as block elements on mobile */
    .stash-filters-row .btn,
    .stash-filters-row .btn-group,
    .stash-filters-row .dropdown,
    .stash-content-type-dropdown-button,
    .stash-tag-dropdown-button {
        display: block;
        width: 100%;
        margin-bottom: 8px;
    }
    
    /* Remove any Bootstrap margin classes on mobile */
    .stash-filters-row .ms-2,
    .stash-filters-row .me-2,
    .stash-filters-row .mx-2 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

/* Add these styles for desktop */

@media (min-width: 768px) {
    /* For desktop view, restore horizontal layout */
    .stash-filters-row .btn,
    .stash-filters-row .btn-group,
    .stash-filters-row .dropdown {
        display: inline-block;
        width: auto;
    }
    
    /* Add appropriate margins on desktop */
    .upload-btn, .select-btn {
        margin-left: 0.5rem;
    }
}

/* Add or update these styles in your Stash.css file */

/* Desktop-specific button styles */
@media (min-width: 768px) {
  /* Reset the full-width styles added for mobile */
  .upload-btn,
  .select-btn {
    display: inline-block !important;
    width: auto !important;
    margin-left: 0.5rem;
    margin-bottom: 0 !important;
  }
}

/* Mobile-specific button styles */
@media (max-width: 767.98px) {
  .upload-btn,
  .select-btn {
    display: block;
    width: 100%;
    margin-top: 8px;
  }
  
  .stash-filters-row .btn,
  .stash-filters-row .btn-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* Add these styles to your existing Stash.css file */

.stash-tag-picker-container {
  display: inline-block;
  vertical-align: middle;
  width: 100%; 
  max-width: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .stash-tag-picker-container {
    max-width: 100%;
    margin-bottom: 15px;
  }
}

/* This ensures the tag picker dropdown doesn't get cut off in the sticky header */
.stash-filters-container {
  z-index: 1030;
  overflow: visible;
}

/* Ensure the tag dropdown appears above content */
.tag-suggestions-wrapper {
  z-index: 1050;
}

/* Make the filter row more responsive */
.stash-filters-row {
  flex-wrap: wrap;
  align-items: flex-start;
}

/* Style selected tag badges to match the rest of the UI */
.tag-badge {
  background-color: #6c757d;
  font-size: 0.85rem;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* Add some spacing between filter elements */
.stash-filters-row > div {
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Additional mobile optimizations */
@media (max-width: 576px) {
  .filter-collapse-container.expanded {
    padding: 10px;
    background: #f8f9fa;
    border-radius: 5px;
    margin-bottom: 15px;
  }
}

/* Add these styles to your existing CSS file */

/* Improved filter container layout */
.stash-filters-container {
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.stash-filters-container.is-sticky {
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  border-bottom: 1px solid #e9ecef;
}

/* Filter rows styling */
.stash-filters-row {
  padding: 10px 0;
}

.stash-actions-row {
  padding: 10px 0;
  border-top: 1px solid #eee;
}

/* Tag picker container */
.stash-tag-filter-container {
  margin: 10px 0;
  width: 100%;
  overflow: visible !important;
  position: relative;
}

.stash-tag-picker-input {
  flex: 1;
  min-width: 200px;
  position: relative;
  z-index: 1;
}

/* Badge styling */
.filter-tag-badge {
  font-size: 0.85rem;
  padding: 5px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: all 0.2s;
}

.filter-tag-badge:hover {
  background-color: #495057;
}

/* Input fields */
.stash-search-input,
.stash-content-type-dropdown-button {
  margin-right: 8px;
  margin-bottom: 8px;
  vertical-align: middle;
}

.stash-search-input {
  min-width: 150px;
  max-width: 250px;
  display: inline-block;
}

/* Button spacing */
.upload-btn,
.select-btn {
  min-width: 120px;
}

/* Active filters display */
.active-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .stash-search-input {
    width: 100%;
    max-width: unset;
    margin-bottom: 10px;
  }
  
  .stash-filters-row > .col,
  .stash-actions-row > .col {
    flex-direction: column;
  }
  
  .stash-search-buttons {
    margin-bottom: 10px;
  }
  
  .stash-content-type-dropdown-button {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .stash-tag-filter-container {
    width: 100%;
  }
  
  .active-filters {
    margin-top: 8px;
    justify-content: flex-start;
    width: 100%;
  }
}

/* Collapse container animation */
.filter-collapse-container {
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease;
  overflow: hidden;
}

.filter-collapse-container.collapsed {
  max-height: 0;
  opacity: 0;
}

.filter-collapse-container.expanded {
  max-height: 1000px; /* Adjust as needed */
  opacity: 1;
}

/* Make sure dropdown containers don't get cut off */
.tag-suggestions-wrapper {
  position: absolute;
  z-index: 1050;
  z-index: 9999 !important;
}

/* Add these styles to your existing CSS file */

/* Filter container layout */
.stash-tag-filter-container {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.stash-tag-picker-input {
  min-width: 200px;
  flex: 0 1 auto;
}

.stash-selected-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  margin-top: 5px;
}

/* On medium screens and up, display side by side */
@media (min-width: 768px) {
  .stash-tag-picker-input {
    max-width: 300px;
    margin-right: 10px;
  }
  
  .stash-selected-tags {
    margin-top: 0;
  }
}

/* Badge styling */
.filter-tag-badge {
  font-size: 0.85rem;
  padding: 5px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: all 0.2s;
}

.filter-tag-badge:hover {
  background-color: #495057;
}

/* Make sure dropdown containers don't get cut off */
.tag-suggestions-wrapper {
  position: absolute;
  z-index: 1050;
  z-index: 9999 !important;
}

/* Add these styles for the improved filter container */

/* Filter container and row styling */
.stash-filters-container {
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 1rem;
  position: sticky;
  z-index: 1030;
  overflow: visible !important; /* Critical to ensure dropdowns remain visible */
  transition: box-shadow 0.3s ease;
}

.stash-filters-container.is-sticky {
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  border-bottom: 1px solid #e9ecef;
}

/* Allow seamless overflow for dropdowns */
.filter-collapse-container {
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease;
  overflow: visible !important;
  position: relative;
}

.filter-collapse-container.collapsed {
  max-height: 0;
  opacity: 0;
  overflow: hidden !important; /* Only hide when collapsed */
}

.filter-collapse-container.expanded {
  max-height: 1000px;
  opacity: 1;
}

/* Search input */
.stash-search-input {
  display: inline-block;
  height: 38px;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  vertical-align: middle;
}

/* Tag picker container */
.stash-tag-filter-container {
  position: relative;
  z-index: 1040;
  margin-top: 10px;
}

.stash-tag-picker-input {
  flex: 0 0 auto;
  min-width: 200px;
  max-width: 300px;
  margin-right: 10px;
}

.stash-selected-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
}

/* Badge styling */
.filter-tag-badge {
  font-size: 0.85rem;
  padding: 0.35em 0.65em;
  transition: background-color 0.2s;
}

.filter-tag-badge:hover {
  background-color: #495057;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .stash-tag-picker-input {
    max-width: 100%;
    margin-right: 0;
    width: 100%;
  }
  
  .stash-selected-tags {
    margin-top: 10px;
  }
  
  .stash-search-input {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

/* Make sure dropdown suggestions appear above other content */
.tag-suggestions-wrapper {
  z-index: 1050;
  z-index: 9999 !important;
}

/* Add these styles to ensure all dropdowns are visible */

/* Make sure all dropdown containers have proper overflow behavior */
.stash-filters-container,
.filter-collapse-container,
.stash-filters-row,
.content-type-dropdown-container,
.stash-tag-filter-container {
  overflow: visible !important;
}

/* Specific styling for the dropdown menu */
.content-type-dropdown-menu {
  z-index: 9999 !important; /* High z-index */
}

/* Fix for dropdown toggle button */
.stash-content-type-dropdown-button {
  position: relative;
}

/* Fix for collapsed filter container */
.filter-collapse-container.collapsed {
  overflow: hidden !important; /* Only hide when collapsed */
}

.filter-collapse-container.expanded {
  overflow: visible !important;
}

/* Ensure Bootstrap dropdowns aren't clipped */
.dropdown-menu {
  z-index: 9999 !important;
}

/* Target Bootstrap's popper container to ensure it doesn't get clipped */
.dropdown-menu.show {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

/* Create a global fix for React-Bootstrap dropdowns */
body > div.dropdown-menu {
  z-index: 9999 !important;
  visibility: visible !important;
}

/* Create a global fix for React-Bootstrap dropdowns */
body > div.dropdown-menu {
  z-index: 9999 !important;
  visibility: visible !important;
}

/* Add these styles for the content type dropdown */

.content-type-dropdown-container {
  display: inline-block;
  position: relative;
}

.content-type-dropdown-button {
  min-width: 120px;
}

/* Make sure the dropdown menu appears above other content */
#content-type-dropdown-menu {
  z-index: 9999 !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0.5rem 0;
  border-radius: 4px;
  border: 1px solid #dee2e6;
}

/* Add hover effect to dropdown items */
#content-type-dropdown-menu .dropdown-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

#content-type-dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
}

/* Add this to your CSS */

.content-type-dropdown-container {
  position: relative;
  z-index: 1040;
}

/* Ensure the dropdown renders above other elements */
.dropdown {
  position: relative;
}

/* Add these styles to support the new layout */

.filter-top-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

/* Control positioning for different screen sizes */
@media (min-width: 992px) {
  .filter-controls {
    flex: 0 1 auto;
    max-width: 65%;
  }
  
  .tag-filter-wrapper {
    flex: 0 1 auto;
    max-width: 35%;
  }
}

@media (min-width: 1200px) {
  .filter-controls {
    max-width: 70%;
  }
  
  .tag-filter-wrapper {
    max-width: 30%;
  }
}

/* Make sure form controls have appropriate width */
.stash-search-input {
  min-width: 150px;
}

.stash-tag-filter-container {
  width: 100%;
}

/* Ensure the dropdown containers have proper z-index and visibility */
.stash-filters-container,
.filter-collapse-container,
.stash-filters-row,
.content-type-dropdown-container,
.stash-tag-filter-container {
  overflow: visible !important;
}

/* Handle smaller screens more gracefully */
@media (max-width: 991.98px) {
  .tag-filter-wrapper {
    width: 100%;
    margin-top: 0.5rem;
  }
  
  .filter-controls {
    width: 100%;
  }
}

/* Make the tag picker input responsive */
.stash-tag-picker-input {
  min-width: 200px;
  max-width: 100%;
}

@media (min-width: 1200px) {
  .stash-tag-picker-input {
    min-width: 250px;
  }
}

/* Update the filter layout styles */

/* Improve the top filter row with better spacing and alignment */
.filter-top-row {
  width: 100%;
  margin-bottom: 0;
}

/* Make the left side controls flex and grow */
.filter-left {
  width: 100%;
}

/* Tag filter wrapper styles */
.tag-filter-wrapper {
  flex: 1;
  min-width: 250px;
}

/* Make the tag picker input sized appropriately */
.stash-tag-picker-input {
  width: auto;
  min-width: 150px;
  max-width: 300px;
}

/* Make selected tags scroll horizontally instead of wrapping */
.stash-selected-tags {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
  max-width: 100%;
  padding-bottom: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.stash-selected-tags::-webkit-scrollbar {
  height: 3px;
}

.stash-selected-tags::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Make badges sit on the same line */
.filter-tag-badge {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0 !important;
  height: 24px;
}

/* Make sure the search input is not too small */
.stash-search-input {
  min-width: 150px;
  max-width: 100%;
}

/* Responsive adjustments */
@media (max-width: 767.98px) {
  .tag-filter-wrapper {
    width: 100%;
  }
  
  .stash-tag-picker-input {
    width: 100%;
    max-width: 100%;
  }
}

/* Ensure the dropdown containers have proper z-index and visibility */
.stash-filters-container,
.filter-collapse-container,
.stash-filters-row,
.content-type-dropdown-container,
.stash-tag-filter-container {
  overflow: visible !important;
}