.meme-def-output {
    font-family: 'Courier New', Courier, monospace;
    white-space: pre;
    font-size: 10pt;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: auto;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 0.75rem;
    margin-top: 0.5rem;
}

.meme-def-output pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: normal;
    overflow-x: visible;
    font-family: monospace;
    font-size: 0.875rem;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: white;
    font-size: 24px;
  }
  
  .overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

.form-container {
    padding-top: 20px;
}

.json-preview {
    margin-top: 20px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.json-preview-header {
    padding: 10px 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.json-preview-header:hover {
    background-color: #e9ecef;
}

.json-preview-header h6 {
    margin: 0;
    font-weight: 500;
}

input.like-number {
    width: 60px;
}

.resize-controls {
  display: flex;
  gap: 15px;
  align-items: center;
}

.resize-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.resize-input-group .form-label {
  margin: 0;
  min-width: 25px;
}

.wysiwyg-column {
    height: 100vh;
    border-right: 1px solid #dee2e6;
    padding: 20px;
    position: sticky;
    top: 0;
}

.wysiwyg-container {
    position: sticky;
    top: 20px;
}

.wysiwyg-preview {
    position: relative;
    overflow: visible;  /* Changed from hidden to visible */
    width: fit-content;
    padding: 0;
    margin: 0;
    line-height: 0;
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    display: inline-block;
}

.wysiwyg-preview img {
    display: block;
    max-width: 100%;
    /* vertical-align: bottom; */
    user-select: none;
    margin: 0;
    padding: 0;
}

.react-draggable {
    z-index: 1000;
}

.react-resizable-handle {
    background-color: #fff;
    border: 1px solid #666;
    width: 10px;
    height: 10px;
}

.form-column {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

.text-overlay-box {
  overflow: visible !important;
}

.text-overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.text-overlay-container .react-draggable {
  pointer-events: auto;
}

/* Specific style for when capturing */
.wysiwyg-preview.capturing-screenshot,
.wysiwyg-preview.capturing-screenshot * {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

/* Production-specific overrides to ensure no borders during capture */
.capturing-screenshot .react-draggable {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  overflow: visible !important;
}

.capturing-screenshot .text-overlay-box {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
}

.wysiwyg-preview.capturing-screenshot img {
  border: 0 !important;
}

/* This rule helps ensure no inherited borders */
.wysiwyg-preview.capturing-screenshot,
.wysiwyg-preview.capturing-screenshot * {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

/* Add to MemeMaker.css */
.meme-basics-header:hover {
  background-color: #e9ecef !important;
}

.meme-basics-body {
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.meme-basics-panel {
  border-radius: .25rem;
  overflow: hidden;
}

/* Add to MemeMaker.css */
.meme-panel-header:hover {
  background-color: #e9ecef !important;
}

.meme-panel-header h5 {
  font-size: 1.1rem;
}

.meme-panel-body {
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.meme-texts-panel, .meme-overlays-panel {
  border-radius: .25rem;
  overflow: hidden;
}

.meme-button-container {
  display: flex;  
  margin-top: 10px;
}

/* Add or update these mobile-specific styles */

@media (max-width: 767.98px) {
  /* Fix layout on mobile by ensuring proper stacking */
  .wysiwyg-column {
    order: 1;
    margin-bottom: 20px;
    height: auto !important;
    max-height: none !important;
  }
  
  .form-column {
    order: 2;
  }
  
  /* Make the wysiwyg container fixed height on mobile */
  .wysiwyg-container {
    height: auto !important;
    min-height: 300px !important;
    max-height: 300px !important;
    overflow: auto;
    position: relative;
    margin-bottom: 1rem;
  }
  
  /* Style the preview image to fit within the container */
  .wysiwyg-preview img {
    max-width: 100%;
    max-height: 280px;
    object-fit: contain;
  }
  
  /* Ensure overlays stay within the preview container */
  .text-overlay-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  /* Fix buttons on mobile */
  .meme-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  /* Make buttons more tappable */
  .meme-button-container .btn {
    flex-basis: 48%;
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
    padding: 0.5rem 0.25rem;
    font-size: 0.875rem;
  }
  
  /* Fix panel headers for better touch targets */
  .meme-panel-header,
  .meme-basics-header,
  .json-preview-header {
    min-height: 50px;
    padding: 0.75rem !important;
  }
  
  /* Make text overlays easier to manipulate on mobile */
  .text-overlay-box,
  .image-overlay {
    min-width: 40px;
    min-height: 20px;
  }
  
  /* Style the resize handles to be more obvious and touchable */
  .react-resizable-handle {
    width: 20px !important;
    height: 20px !important;
    background-color: rgba(0, 123, 255, 0.3);
    border-radius: 50%;
  }
  
  /* Fix the API-generated image display on mobile */
  .json-preview img {
    max-width: 100%;
    height: auto;
  }
  
  /* Make sure modal footers don't overflow */
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }
  
  /* Improve form inputs for touch */
  input, select, textarea {
    font-size: 16px !important; /* Prevent iOS zoom on focus */
    padding: 8px 12px !important;
  }
}