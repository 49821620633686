.tag-picker-container {
  width: 100%;
  position: relative;
  overflow: visible;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  min-height: 38px;
}

.selected-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag-badge {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0 !important;
  height: 24px;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 16px;
  transition: all 0.2s ease;
}

.tag-badge:hover {
  background-color: #495057;
}

.remove-tag-btn {
  margin-left: 6px;
  opacity: 0.7;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.remove-tag-btn:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.tag-search-container {
  position: relative;
  width: 100%;
}

.tag-suggestions-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 9999; /* Very high z-index to ensure it's above everything */
  margin-top: 2px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.tag-suggestions {
  max-height: 240px; /* Reasonable height for desktop */
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  border-radius: 6px;
}

@media (max-width: 576px) {
  .tag-suggestions {
    max-height: 50vh; /* Use viewport height on mobile */
  }
}

.tag-suggestions::-webkit-scrollbar {
  width: 4px;
}

.tag-suggestions::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.tag-suggestions-list,
.available-tags-list {
  padding-bottom: 4px;
}

.tag-suggestion-item {
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.tag-suggestion-item:last-child {
  border-bottom: none;
}

.tag-suggestion-item:hover,
.tag-suggestion-item.active {
  background-color: #f8f9fa;
}

.tag-suggestion-item.new-tag {
  color: #0d6efd;
}

.tag-suggestion-item.already-selected {
  background-color: #e9ecef;
  font-style: italic;
  opacity: 0.7;
}

.tag-suggestion-empty {
  padding: 12px;
  color: #6c757d;
  text-align: center;
  font-style: italic;
}

.available-tags-section {
  border-top: 1px solid #dee2e6;
  padding-top: 0.5rem;
}

.available-tags-header {
  padding: 8px 12px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #6c757d;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.more-tags-available {
  padding: 8px 12px;
  color: #6c757d;
  text-align: center;
  font-size: 0.85rem;
  border-top: 1px dashed #dee2e6;
}

.no-tags-selected {
  padding: 4px 0;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .tag-suggestion-item {
    padding: 12px 16px; /* Larger touch targets */
  }
  
  .remove-tag-btn {
    width: 20px; /* Larger tap target */
    height: 20px;
  }
}

/* Make sure the filter container doesn't clip its children */
.filter-collapse-container {
  overflow: visible !important; /* Override any collapse behavior that might hide dropdowns */
  position: relative;
  z-index: 1040; /* High enough to be above most content */
}

/* Add these styles for the selected tags */

.tag-picker-selected-tags {
  margin-top: 8px;
}

.tag-picker-selected-tags.flex-nowrap {
  overflow-x: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
  max-width: 100%;
  padding-bottom: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tag-picker-selected-tags.flex-nowrap::-webkit-scrollbar {
  height: 3px;
}

.tag-picker-selected-tags.flex-nowrap::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Add styles for the selected tags display */

/* Selected tags container styles */
.tag-picker-selected-tags {
  margin-top: 8px;
  padding-bottom: 4px;
}

/* Inline tags (horizontal scrolling) */
.tag-picker-selected-tags-inline {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: thin;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tag-picker-selected-tags-inline::-webkit-scrollbar {
  height: 3px;
}

.tag-picker-selected-tags-inline::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Block tags (wrapped) */
.tag-picker-selected-tags-block {
  display: flex;
  flex-wrap: wrap;
}

/* Tag badge styles */
.tag-badge {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 24px;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem !important;
  border-radius: 16px;
  transition: all 0.2s ease;
}

.tag-badge:hover {
  background-color: #495057;
  cursor: pointer;
}

/* Dropdown styles */
.tag-suggestions-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 9999;
  margin-top: 2px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.tag-suggestions {
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
}

/* Suggestion items */
.tag-suggestions-list,
.available-tags-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.tag-suggestion-item {
  padding: 8px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.tag-suggestion-item:last-child {
  border-bottom: none;
}

.tag-suggestion-item:hover,
.tag-suggestion-item.active {
  background-color: #f8f9fa;
}

.tag-suggestion-item.new-tag {
  color: #28a745;
}

.tag-suggestion-item.already-selected {
  opacity: 0.6;
  color: #777;
}

/* Empty and header styles */
.tag-suggestion-empty {
  padding: 8px 15px;
  color: #6c757d;
  font-style: italic;
}

.available-tags-section {
  margin-top: 8px;
  border-top: 1px solid #dee2e6;
}

.available-tags-header {
  padding: 8px 15px;
  font-weight: bold;
  color: #6c757d;
  font-size: 0.9rem;
  background-color: #f8f9fa;
}

.more-tags-available {
  padding: 8px 15px;
  color: #6c757d;
  font-style: italic;
  font-size: 0.9em;
  text-align: center;
  background-color: #f8f9fa;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .tag-picker-container {
    width: 100%;
  }
}