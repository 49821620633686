.paste-area {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.2s ease;
}

.paste-area:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
}

.image-preview {
  max-width: 100%;
  max-height: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
}

.mobile-toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0,0,0,0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  z-index: 9999;
}

/* URL input section */
.url-input-section {
  margin-bottom: 1rem;
}

.url-actions {
  margin-top: 0.5rem;
}

/* Add space between buttons in button group */
.url-action-button {
  margin-right: 0.25rem;
}

/* Divider between upload and URL sections */
.section-divider {
  margin: 1.5rem 0;
  border-top: 1px solid #dee2e6;
  position: relative;
}

.divider-text {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 0 10px;
  color: #6c757d;
}

/* Success result section */
.result-section {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


.universal-file-button {
  padding: 0.75rem;
  font-size: 1.1rem;
  transition: all 0.2s ease;
}

.universal-file-button:hover:not(:disabled) {
  background-color: #0d6efd;
  color: white;
}