.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    text-align: center;
    color: white;
}

.overlay-content > div {
    margin-top: 10px;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.me-2 {
  margin-right: 0.5rem;
}

.generated-image-container {
    max-height: 80vh;
    overflow: auto;
}

.generated-image {
    max-width: 100%;
    height: auto;
    display: block;
}