.text-def-container {
    font-family: 'Courier New', Courier, monospace;
    padding: 15px;
    max-width: 100%;
}

.text-entry {
    border: 1px solid #dee2e6;
    padding: 15px;
    border-radius: 4px;
    background-color: #f9f9f9;
    margin-bottom: 15px;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #f8f9fa;
}

.json-display {
    white-space: pre;
    margin-top: 20px;
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.dimension-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 600px;
    width: 100%;
    gap: 1rem;
    align-items: center;
}

.pos-input-group {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 150px;
    margin-right: 10px;
    gap: 0.5rem;
}

.pos-input-group .form-label {
    display: none;  /* Hide the X,Y,W,H labels */
}

.text-def-container .form-label {
    text-align: right;
    padding-right: 10px;
}

.pos-checkbox {
    margin-left: 10px;
    white-space: nowrap;
}

input[type="number"] {
    width: 90px;
}

.outline-input {
    width: 200px;
}

.outline-weight-input {
    width: 60px;
    width: 70px;
}

.form-label.mb-0 {
    text-align: left;
}

.form-label {
    margin-bottom: 0.5rem;
    text-align: right;
    padding-right: 10px;
}

.form-control, .form-select {
    width: 100%;
    max-width: none;
}

/* Make color inputs more compact */
.color-input {
    min-width: 100px;    
    width: 100px;
}

/* Add media query for smaller screens */
@media (max-width: 576px) {  /* Bootstrap's sm breakpoint */
    .text-def-container .form-label {
        text-align: left;
        padding-right: 0;
    }
}

.form-group {
    margin-bottom: 0.5rem;
}

.advanced-settings-container {
    background-color: #ffffff;
    border-radius: 0 0 0.25rem 0.25rem;
    margin-top: -1px;
    padding-top: 0.5rem;
    transition: max-height 0.3s ease-in-out;
}

/* Custom button styling for the accordion toggle */
.btn-outline-secondary.text-left {
    text-align: left;
}

/* Add to TextDef.css */
@media (max-width: 768px) {
  .pos-input {
    font-size: 16px; /* Prevents iOS zoom on focus */
    min-height: 40px; /* Makes touch targets larger */
    padding: 8px; /* More padding for easier touch */
  }
  
  .dimension-controls {
    flex-direction: column;
  }
  
  .pos-input-group {
    margin-bottom: 8px;
    width: 100%;
  }
}

/* Enhanced mobile styling for number inputs */
@media (max-width: 768px) {
  .pos-input, .outline-weight-input {
    font-size: 16px !important; /* Prevents iOS zoom on focus */
    min-height: 40px !important; /* Makes touch targets larger */
    padding: 8px !important; /* More padding for easier touch */
  }
  
  .dimension-controls {
    flex-direction: column !important;
  }
  
  .pos-input-group {
    margin-bottom: 8px !important;
    width: 100% !important;
  }
  
  /* Increase spacing between advanced settings fields */
  .advanced-settings-container .form-group {
    margin-bottom: 16px !important;
  }
  
  /* Make form labels more readable on small screens */
  .advanced-settings-container .form-label {
    font-size: 14px !important;
  }
}