.user-profile-title {
    color: #333;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
}

.user-card {
    border-radius: 0.75rem;
    overflow: hidden;
    border: none;
}

.user-header {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
}

.user-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0d6efd;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin-right: 1rem;
}

.user-info {
    flex: 1;
}

.user-details .list-group-item {
    padding: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: none;
}

.user-details .list-group-item:first-child {
    border-top: none;
}

.tag-collection {
    display: flex;
    flex-wrap: wrap;
}

.tag-item {
    font-size: 0.85rem;
    padding: 0.4rem 0.7rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 20px;
}

.clickable-tag {
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

.clickable-tag:hover {
  background-color: #6c757d !important; /* Slightly darker shade */
  transform: scale(1.05);
}

.clickable-tag:active {
  transform: scale(0.95);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .user-header {
        flex-direction: column;
        text-align: center;
    }
    
    .user-avatar {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    
    .user-info {
        text-align: center;
    }
}